.main-slider {
	width: inherit;
}

.thumbnail-slider .slick-list {
	display: grid;
	place-content: start;
}

.product-carousel .slick-slide > div {
	margin-right: 0rem !important;
	margin-bottom: 1.5rem; 
}

.thumbnail-slider .slick-slide > div {
	margin-right: 0rem !important;
	margin-bottom: 0rem !important; 
}

.thumbnail-slider .slick-slide {
	margin-right: 8px !important;
	margin-bottom: 0rem !important; 
	width: max-content !important;
}

.thumbnail-slider .slick-slider .slick-list .slick-track {
	transform: translate3d(0, 0, 0) !important;
	width: auto !important;
}