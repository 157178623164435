.navbar-bg-gradient {
  position: absolute;
  width: 100vw;
  bottom: -260px;
	left: 0rem;
	height: 360px;
  background-image: url('../../common/assets/icons/top-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.navbar-bg-gradient::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(0deg, rgba(240, 243, 249, 1) 0%, transparent 100%);
}

.navbar-bg {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.navbar {
  position: relative;
  width: 100%;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .navbar-bg-gradient {
    left: -1rem;
  }
}
