.checkbox {
	display: inline-block;

	width: 14px;
	height: 14px;
	box-sizing: border-box;

	cursor: pointer;
	transition: all 0.2s;
	text-align: center;

	border: none;
	border-radius: 6px;

	font-size: 20px;
	accent-color: var(--main-color);
}
