.slick-dots li button:before {
    /* Your styles */
    color: transparent; /* Hide the default dots */
}

.slick-dots li.slick-active button:before {
    /* Your styles */
    color: transparent; /* Also hide for active state */
}

.slick-prev {
    display: none !important;
}

.slick-next {
    display: none !important;
}

