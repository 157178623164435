@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body {
	margin: 0;
	padding: 0;

	color: var(--text-color);

	font-family: var(--font-family);
}

a {
	text-decoration: none;

	color: inherit;
}

* {
	box-sizing: border-box;
}

*:focus-visible {
	border: none;
	outline: none;
}

:root {
	--main-color: #7f56d9;
	--bg-color: #f0f3f9;
	--button-color: #f5f5f5;
	--stroke-color: #d0d5dd;
	--gray-one: #f2f2f2;
	--gray-two: #868686;
	--favourite-color: #ea8a86;
	--red-color: #b90000;
	--waiting-color: #fe9a04;
	--preparing-color: #ff5c00;
	--return-color: #344054;
	--cancel-color: #ef0000;
	--delivery-color: #027a48;
	--font-family: "Inter", sans-serif;
}

.truncate-1-lines {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-2-lines {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-3-lines {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clamp-text-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: calc(1.5em * 1);
}

.clamp-text-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: calc(1.5em * 3);
}

.clamp-text-4 {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	padding: 0 1em;
	min-height: calc(
		1.5em * 4
	); /* Adjust based on your actual font-size and line-height */
}
