.bg-rgba-60 {
	background-color: rgba(255, 255, 255, 0.60);
}

.bg-rgba-40 {
	background-color: rgba(255, 255, 255, 0.40);
}

.gradient-wrapper {
  position: absolute;
  /* top: -190px; */
  /* left: -43px; */
  width: 100%;
  height: 100%; 
  z-index: 10; 
}

.ellipticalImages-bg {
  position: absolute;
  width: 130%;
	height: 100%;
  background-image: url('../../common/assets/icons/main_gradient2.svg');
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-mask-image: linear-gradient(to top, transparent, white 10%), radial-gradient(circle at bottom left, transparent 90%, white 100%), radial-gradient(circle at bottom right, transparent 90%, white 100%);
  mask-image: linear-gradient(to top, transparent, white 10%), radial-gradient(circle at bottom left, transparent 90%, white 100%), radial-gradient(circle at bottom right, transparent 90%, white 100%);
  mask-mode: alpha, luminance, luminance;
  -webkit-mask-mode: alpha, luminance, luminance;
  -webkit-mask-composite: source-over;
          mask-composite: add;
}


@media (min-width: 320px) and (max-width: 1024px) {
  .ellipticalImages-bg {
    height: 450px;
  }
}

